<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    EMAIL & TEXT TEMPLATES
                </div>
            </div>
            <div class="page_head-item-container">
                <b-button class="page_head-new-btn" @click="newEmail()" id="new_email_btn">+New Email</b-button>
                <b-button class="page_head-new-btn" @click="newText()" id="new_text_btn">+New Text</b-button>
            </div>
        </div>
        <div class="page_subtitle">These templates appear in your lead profiles.
        </div>
        <div class="page_content">
            <b-table hover :items="allEmailTextTemplates" :fields="fields" responsive="sm">
                <template v-slot:cell(name)="data">
                    <div class="item_name item_center">
                        {{data.item.name}}
                    </div>
                </template>
                <template v-slot:cell(lead_type)="data">
                    <div class="item_center">
                        {{data.item.lead_type}}
                    </div>
                </template>
                <template v-slot:cell(funnel_part)="data">
                    <div class="item_center">
                        {{data.item.funnel_part}}
                    </div>
                </template>
                <template v-slot:cell(type)="data">
                    <div class="item_center">
                        {{data.item.type}}
                    </div>
                </template>

                <template v-slot:cell(view)="data">
                    <div class="item_view" id="template_view" @click="templateViewModal(data.item)">
                    </div>
                </template>
                <template v-slot:cell(edit)="data">
                    <div class="item_edit" id="template_edit-btn" @click="templateEditModal(data.item)">
                    </div>
                </template>
                <template v-slot:cell(delete)="data">
                    <div class="item_delete" id="template_delete" @click="templateDeleteModal(data.item)">
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmailTextTemplates",
        data() {
            return {
                borderPosition: true,
                fields: [
                    {
                        key: 'name',
                        label: 'NAME/SUBJECT LINE',
                    },
                    {
                        key: 'lead_type',
                        label: 'LEAD TYPE',
                    },
                    {
                        key: 'funnel_part',
                        label: 'PART OF FUNNEL',
                    },
                    {
                        key: 'type',
                        label: 'TYPE',
                    },
                    {
                        key: 'view',
                        label: 'VIEW',
                    },
                    {
                        key: 'edit',
                        label: 'EDIT'
                    },
                    {
                        key: 'delete',
                        label: 'DELETE',
                    },
                ],
                allEmailTextTemplates: [
                    {
                        name: 'Hey There',
                        lead_type: 'buyer',
                        funnel_part: 'Initial outreach',
                        type: 'email'
                    },
                    {
                        name: 'Hey There',
                        lead_type: 'buyer',
                        funnel_part: 'Initial outreach',
                        type: 'email'
                    }
                ]
            }
        },
        created() {
            alert.$on("clickOn", border => {
                this.borderPosition = border;
            })
        },
        methods: {
            newEmail() {
                this.$router.push('/emailTemplate')
            },
            newText() {
                this.$router.push('/textTemplate')
            },
            templateViewModal(item) {

            },
            templateEditModal(item) {

            },
            templateDeleteModal(item) {

            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1700px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;

        &.straight_length {
            max-width: 1600px;
            transition: .4s;
        }
    }
    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 5px;

        &-item {
            display: flex;
            align-items: center;
            &-container {
                max-width: 400px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }

        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }

        &-new-btn {
            width: 180px;
            height: 44px;
            border-radius: 5px;
            background: $light_blue;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border: none;

            &:focus {
                box-shadow: none;
            }

            &:active {
                background: $main_blue !important;
            }
        }
    }
    &_subtitle {
        width: 100%;
        display: flex;
        padding: 0 30px 20px;
        justify-content: flex-start;
        color: $light_blue;
    }
    &_content {
        width: 100%;
        padding: 10px 30px 0;

        ::v-deep table {
            border: 1px solid #dee2e6;

            td {
                vertical-align: middle;
            }
        }

        ::v-deep .table thead th {
            color: $main_blue;
            text-transform: uppercase;
            text-align: center;
        }

        & .item {
            &_name {
                color: $light_blue;
            }

            &_center {
                display: flex;
                justify-content: center;
            }
            &_switch {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_view {
                width: 30px;
                height: 20px;
                background: url("~@/assets/images/view_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }

            &_edit {
                width: 30px;
                height: 20px;
                background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }

            &_delete {
                width: 24px;
                height: 30px;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }
}
</style>